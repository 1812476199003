import React, { createContext, useContext, useState } from 'react';

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customerState, setCustomerState] = useState({
    customer: {},
    orders:[],
    addresses:[]
  });

  return (
    <CustomerContext.Provider value={[customerState, setCustomerState]}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => useContext(CustomerContext);