import React from 'react';

import { NotificationProvider } from './src/context/AddItemNotificationProvider';
import { AuthProvider } from './src/context/AuthProvider';
import { CartProvider } from './src/context/CartProvider';
import { CustomerProvider } from './src/context/CustomerProvider';

import AutoLoginWrapper from './src/components/AutoLoginWrapper/AutoLoginWrapper';
import AutoCartFetchWrapper from './src/components/AutoCartFetchWrapper/AutoCartFetchWrapper';

import { ApolloProvider } from '@apollo/client';
import client from './apollo-client';

export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={client}>
        <CartProvider>
            <AuthProvider>
                <CustomerProvider>
                    <NotificationProvider>
                        <AutoLoginWrapper>
                            <AutoCartFetchWrapper>
                                {element}
                            </AutoCartFetchWrapper>
                        </AutoLoginWrapper>
                    </NotificationProvider>
                </CustomerProvider>
            </AuthProvider>
        </CartProvider>
    </ApolloProvider>
);
