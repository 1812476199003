// AutoLoginWrapper.js
import React from 'react';
import { useAutoLogin } from '../../hooks/useAutoLogin';

const AutoLoginWrapper = ({ children }) => {
    useAutoLogin();
    return <>{children}</>;
};

export default AutoLoginWrapper;
