import React from 'react';
import { useAutoCartFetch } from '../../hooks/useAutoCartFetch';

const AutoCartFetchWrapper = ({ children }) => {
    const isOrderConfirmPage = typeof window !== 'undefined' 
        && window.location.pathname === '/orderConfirm/';
    useAutoCartFetch(isOrderConfirmPage);

    return <>{children}</>;
};

export default AutoCartFetchWrapper;
