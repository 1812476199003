import axios from 'axios';

import { useAuthContext } from '../context/AuthProvider';
import { useCustomerContext } from '../context/CustomerProvider';
import { useCartContext } from '../context/CartProvider';

import Cookies from 'js-cookie';

import { useStaticQuery, graphql } from 'gatsby';

function useAuthAction() {
    const configData = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    microserviceUrl,
                    siteUrl
                }
            }
        }
    `);

    const baseUrl = configData.site.siteMetadata.microserviceUrl;
    const [authState, setAuthState] = useAuthContext()
        || [{}, () => true];
    const [_customerState, setCustomerState] = useCustomerContext()
        || [{}, () => true];
    const [_cartState, setCartState] = useCartContext()
        || [{}, () => true];

    const getJwtToken = () => Cookies.get('token');
    const getHeaders = () => {
        let headers = {};
        const token = getJwtToken();

        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        return headers;
    }

    const register = async (userData) => {
        try {
            const response = await axios.post(`${baseUrl}auth/register`, userData);
            const { token, customer_id } = response.data;

            if (token) {
                setAuthState({ token, user: customer_id });
                Cookies.set('token', token, { path: '/' });
            }

            return true;
        } catch (error) {
            console.error("Register failed:", error);
        }
    };

    const login = async (credentials) => {
        try {
            const response = await axios.post(`${baseUrl}auth/login`, credentials);
            const { token, customer_id } = response.data;

            if (token) {
                setAuthState({ token, user: customer_id });
                Cookies.set('token', token, { path: '/' });
            }

            return true;
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const logout = async () => {
        try {
            const response = await axios.post(
                `${baseUrl}auth/logout`,
                {},
                {
                    headers: { ...getHeaders() }
                }
            );

            if (response.data.status === 'success') {
                //We'll remove it locally no matter what.
            }

            Cookies.remove('token', { path: '/' });
            Cookies.remove('cartId', { path: '/' });

            setAuthState({ token: null, user: null });
            setCustomerState({
                customer: {},
                orders: [],
                addresses: []
            });
            setCartState({ cart: {} });

            return true;
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const forgotPassword = async (email) => {
        try {
            const response = await axios.post(`${baseUrl}auth/forgot-password`, { email });
            const { status } = response.data;

            if (status === 'success') {
                return true;
            }
        } catch (error) {
            console.error("Reset password failed:", error);
        }

        return false;
    };

    const resetPasswordEmail = async (data) => {
        try {
            const response = await axios.post(`${baseUrl}auth/reset-password-email`, data);
            const { status } = response.data;

            if (status === 'success') {
                return true;
            }
        } catch (error) {
            console.error("Reset password failed:", error);
        }

        return false;
    };

    const resetPassword = async (data) => {
        console.debug(data);
        try {
            const response = await axios.post(
                `${baseUrl}auth/reset-password`,
                data,
                {
                    headers: { ...getHeaders() }
                }
            );
            const { status } = response.data;

            if (status === 'success') {
                return true;
            }
        } catch (error) {
            console.error("Reset password failed:", error);
        }

        return false;
    };

    const isAuth = () => !!authState.token;

    return { register, login, logout, forgotPassword, resetPassword, resetPasswordEmail, isAuth };
};

export default useAuthAction;
