import React, { useEffect } from 'react';

import useCart from '../hooks/useCart';
import Cookies from 'js-cookie';

import { useCartContext } from '../context/CartProvider';

export const useAutoCartFetch = (disabled = false) => {
    const {
        getCart
    } = useCart();

    const cartId = Cookies.get('cartId');
    const [cartContext] = useCartContext() || [{}];
    
    useEffect(() => {
        const shouldFetchCart = cartId && !cartContext?.id && !disabled;
        if (shouldFetchCart) {
            const fetchCartInfo = async () => {
                try {
                    const cart = getCart(cartId);
                } catch (err) {
                    console.debug(err);
                }
            };

            fetchCartInfo();
        }

        //Not sure about this.  Depends how we gate the orderConfirm page.
        if(disabled && cartId){
            Cookies.remove('cartId', { path: '/' });
        }
    }, [disabled]);
};
