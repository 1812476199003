import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useCustomerContext } from '../context/CustomerProvider';
import { useAuthContext } from '../context/AuthProvider';

import axios from 'axios';
import Cookies from 'js-cookie';

export const useAutoLogin = () => {
    const configData = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    microserviceUrl,
                    siteUrl
                }
            }
        }
    `);

    const baseUrl = configData.site.siteMetadata.microserviceUrl;

    const [_authState, setAuthState] = useAuthContext() 
        || [{}, () => true];
    const [customerContext, setCustomerContext] = useCustomerContext() 
        || [{}, () => true];

    const getJwtToken = () => Cookies.get('token');
    const getHeaders = () => {
        let headers = {};
        const token = getJwtToken();

        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        return headers;
    }

    const token = getJwtToken();
    const customerId = customerContext?.customer?.customer?.id;
    useEffect(() => {
        if (getJwtToken() && !customerId) {
            const fetchCustomerInfo = async () => {
                try {
                    const response = await axios.get(
                        `${baseUrl}customer/`,
                        {
                            headers: { ...getHeaders() }
                        }
                    );
                    const { bcCustomer, idpUser, customer } = response.data;
                    setAuthState({
                        token,
                        user: customer.id
                    });
                    setCustomerContext({ ...customerContext, customer: { bcCustomer, idpUser, customer } });
                } catch (err) {
                    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                        Cookies.remove('token', { path: '/' });
                        return;
                    }

                    console.debug(err);
                }
            };

            fetchCustomerInfo();
        }
    }, [token, customerId]);
};
