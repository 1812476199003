import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

//TODO - IMPLEMENT ERROR MESSAGE
export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        token: null,
        user: null,
        error: null
    });

    return (
        <AuthContext.Provider value={[authState, setAuthState]}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);