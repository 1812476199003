import { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useCartContext } from '../context/CartProvider';

import useAuth from './useAuth';

import axios from 'axios';
import Cookies from 'js-cookie';

//TODO - PROBABLY HAVE TO PUT LOADING ON THE CONTEXT TOO, TO SHARE ACROSS COMPONENTS, BUT WAIT FOR ASK
function useCart() {
    const configData = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    microserviceUrl,
                    siteUrl
                }
            }
        }
    `);

    const { logout } = useAuth();

    const baseUrl = configData.site.siteMetadata.microserviceUrl;
    const [cart, setCart] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [cartContext, setCartContext] = useCartContext()
        || [{}, () => true];

    const getJwtToken = () => Cookies.get('token');
    const getHeaders = () => {
        let headers = {};
        const token = getJwtToken();

        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }

        return headers;
    }

    const initRequest = () => {
        setLoading(true);
        setError(false);
    }

    const createCart = async (customerId, items) => {
        initRequest();
        try {
            const response = await axios.post(
                `${baseUrl}cart`,
                {
                    line_items: [...items]
                },
                {
                    headers: { ...getHeaders() }
                }
            );
            const { cart } = response.data;

            Cookies.set('cartId', cart.id, { path: '/' });
            setCart(cart);
            setCartContext({ cart });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                logout();
            } else {
                setError(err);
            }
        }
    };

    const getCart = async cartId => {
        initRequest();
        try {
            const response = await axios.get(
                `${baseUrl}cart/${cartId}`,
                {
                    headers: { ...getHeaders() }
                }
            );

            const { cart } = response.data;

            setCart(cart);
            setCartContext({ cart });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                logout();
            } else {
                setError(err);
            }
        }
    };

    const addItem = async (item, cartId) => {
        initRequest();
        try {
            const response = await axios.post(
                `${baseUrl}cart/${cartId}/items`,
                {
                    line_items: [...item]
                },
                {
                    headers: { ...getHeaders() }
                }
            );

            const { cart } = response.data;

            setCart(cart);
            setCartContext({ cart });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                logout();
            } else {
                setError(err);
            }
        }
    };

    const removeItem = async (itemId, cartId) => {
        initRequest();
        try {
            const response = await axios.delete(
                `${baseUrl}cart/${cartId}/items/${itemId}`,
                {
                    headers: { ...getHeaders() }
                }
            );
            let { cart } = response.data;
            if (!cart) {
                cart = {};
                setCart(null);
                setCartContext({ cart });
            } else {
                setCart(cart);
                setCartContext({ cart });
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                logout();
            } else {
                setError(err);
            }
        }
    };

    const updateItem = async (itemId, cartId, updatedData) => {
        initRequest();
        try {
            const response = await axios.put(
                `${baseUrl}cart/${cartId}/items/${itemId}`,
                updatedData,
                {
                    headers: { ...getHeaders() }
                }
            );
            const { cart } = response.data;
            setCart(cart);
            setCartContext({ cart });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                logout();
            } else {
                setError(err);
            }
        }
    };

    const getCartUrl = async (cartId) => {
        initRequest();
        try {
            const response = await axios.get(
                `${baseUrl}cart/checkout-url/${cartId}`,
                {
                    headers: { ...getHeaders() }
                }
            );
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                logout();
            } else {
                setError(err);
            }
        }
    };

    const getCartQty = () => {
        let items = [];
        if (cartContext?.cart?.line_items) {
            const { custom_items, digital_items, physical_items } = cartContext.cart.line_items;
            items = [...custom_items, ...digital_items, ...physical_items];
            let count = 0;
            items.forEach(item => {
                count = count + item.quantity;
            })

            return count;
        }
    }

    const setCustomerIdOnCart = async (cartId, bcCustomerId) => {
        initRequest();
        try {
            const response = await axios.put(
                `${baseUrl}cart/${cartId}`,
                {
                    customer_id: bcCustomerId
                },
                {
                    headers: { ...getHeaders() }
                }
            );

            if (response.data.status === 'success') {
                await getCart(cartId);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                logout();
            } else {
                setError(err);
            }
        }
    };

    const addCoupon = async (cartId, couponCode) => {
        initRequest();
        try {
            const response = await axios.post(
                `${baseUrl}cart/${cartId}/coupons`,
                {
                    coupon_code: couponCode
                },
                {
                    headers: { ...getHeaders() }
                }
            );

            if (response.data.status === 'success') {
                await getCart(cartId);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err?.response?.data?.message ?? true);
        }
    };

    const removeCoupon = async (cartId, couponCode) => {
        initRequest();
        try {
            const response = await axios.delete(
                `${baseUrl}cart/${cartId}/coupons/${couponCode}`,
                {
                    headers: { ...getHeaders() }
                }
            );

            if (response.data.status === 'success') {
                await getCart(cartId);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const clearError = () => setError(false);

    return {
        cart,
        loading,
        error,
        clearError,
        createCart,
        getCart,
        addItem,
        removeItem,
        updateItem,
        getCartQty,
        getCartUrl,
        setCustomerIdOnCart,
        addCoupon,
        removeCoupon
    };
}

export default useCart;
