import React, { createContext, useState, useEffect } from 'react';

const defaultState = {
    open: false,
    product: null,
    qty: 0
};

export const NotificationContext = createContext(defaultState);

export const NotificationProvider = ({ children }) => {
    const [state, setState] = useState(defaultState);

    const showNotification = (product, qty) => {
        setState({ ...state, open: true, product, qty });
    };

    const closeNotification = () => {
        setState({ ...state, open: false, product: null, qty: 0 });
    };

    useEffect(() => {
        if (state?.open === true) {
            setTimeout(() => {
                closeNotification();
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <NotificationContext.Provider
            value={{
                state,
                setState,
                showNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;
